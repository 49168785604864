import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { IHolding } from '../../types/models';

type MapStoreState = {
  loading: boolean;
  holdings: IHolding[];
  setLoading: (loading: boolean) => void;
  setHoldings: (holdings: IHolding[]) => void;
};

export const useMapStore = create(
  persist(
    immer<MapStoreState>(set => ({
      loading: false,
      holdings: [],
      setLoading: loading =>
        set(draft => {
          draft.loading = loading;
        }),
      setHoldings: holdings =>
        set(draft => {
          draft.holdings = holdings;
        }),
    })),
    {
      name: 'map',
      partialize: state => ({
        holdings: state.holdings,
      }),
    },
  ),
);

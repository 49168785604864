import React, { Suspense } from 'react';

import ReactDOM from 'react-dom/client';

import App from './App';
import { DebugOverlay } from './components/3_organism/DebugOverlay/DebugOverlay';
import { Loader } from './components/3_organism/Loader/Loader';
import { AuthenticationProvider } from './components/5_providers/AuthenticationProvider/AuthenticationProvider';
import { GameServerProvider } from './components/5_providers/GameServerProvider/GameServerProvider';
import './index.css';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <AuthenticationProvider>
      <GameServerProvider>
        <Suspense>
          <App />
        </Suspense>
      </GameServerProvider>
    </AuthenticationProvider>
    <Loader />
    <DebugOverlay />
  </React.StrictMode>,
);

reportWebVitals();

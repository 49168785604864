export const models = {
  // holdings
  holding_tribe: '/models/rts/Castle.glb',
  holding_castle: '/models/rts/Castle Fortress.glb',
  holding_city: '/models/rts/Houses.glb',
  holding_temple: '/models/rts/Temple.glb',
  // buildings
  building_barracks: '/models/rts/Barracks.glb',
  building_castle: '/models/rts/Castle.glb',
  building_crops: '/models/rts/Crops.glb',
  building_farm: '/models/rts/Farm.glb',
  building_fortress: '/models/rts/Fortress.glb',
  building_house: '/models/rts/House.glb',
  building_hut: '/models/rts/hut.glb',
  building_temple: '/models/rts/Temple.glb',
  building_windmill: '/models/rts/Windmill.glb',
  building_market: '/models/rts/Market Stalls.glb',
} as const;

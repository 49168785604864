import axios from 'axios';

import { ShardType } from '../../types/login-server';

export const apiUri = process.env.REACT_APP_LOGIN_SERVER_URI;

console.log('apiUri', apiUri);

export const ls = axios.create({
  baseURL: apiUri,
  timeout: 3000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

export const getShardList = () =>
  ls.get<{ shards: ShardType[] }>('/shards').then(res => res.data.shards);

// client.interceptors.request.use(
//   async config => {
//     const token = await getIdToken();
//     (config as any).headers['Authorization'] = `Bearer ${token}`;
//     return config;
//   },
//   error => Promise.reject(error),
// );

// client.interceptors.response.use(
//   success => success,
//   async error => {
//     if (error.response.status === 401) {
//       await auth.signOut();
//       alert('Your session has expired. Please log in again.');
//     } else if (
//       String(error.response.status).startsWith('4') &&
//       error.response.status !== 404
//     ) {
//       alert(error.response.data.errorMessage);
//     }

//     return Promise.reject(error);
//   },
// );

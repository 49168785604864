import { useGLTF } from '@react-three/drei';
import seedrandom from 'seedrandom';
import { degToRad } from 'three/src/math/MathUtils';
import { v4 } from 'uuid';

import { randomBetween } from '../utils/fiber';

type Model = {
  name: string;
  model: string;
};

const models: Model[] = [
  {
    name: 'logs',
    model: '/models/rts/Logs.glb',
  },
  {
    name: 'mine',
    model: '/models/rts/Mine.glb',
  },
  {
    name: 'trees-1',
    model: '/models/rts/Pine Trees.glb',
  },
  {
    name: 'trees-2',
    model: '/models/rts/Trees.glb',
  },
  {
    name: 'trees-3',
    model: '/models/rts/Trees cut.glb',
  },
  {
    name: 'gold',
    model: '/models/rts/Resource Gold.glb',
  },
  {
    name: 'rocks',
    model: '/models/rts/Rocks.glb',
  },
  {
    name: 'rock',
    model: '/models/rts/Rock.glb',
  },
];

models.forEach(model => useGLTF.preload(model.model));

const rnd = seedrandom('city');

// randomly put models on the map 100 x 100
// each model can be placed multiple times
export const cityTerrainItems = models.flatMap(model => {
  const count = model.name.startsWith('trees-1')
    ? Math.floor(randomBetween(90, 200, rnd))
    : Math.floor(randomBetween(3, 10, rnd));
  return new Array(count).fill(0).map(() => ({
    ...model,
    uid: v4(),
    x: randomBetween(-25, 25, rnd),
    z: randomBetween(-25, 25, rnd),
    rotation: degToRad(Math.floor(rnd() * 360)),
  }));
});

import { Canvas } from '@react-three/fiber';

import './data/preloader';
import { CityScene } from './scenes/CityScene/CityScene';
import { WorldScene } from './scenes/WorldScene/WorldScene';
import { useHoldingStore } from './store/game/holding-store';

export default function App() {
  const selectedHolding = useHoldingStore(state => state.selectedHolding);
  return (
    <Canvas shadows camera={{ position: [0, 4, 0] }}>
      {selectedHolding === 0 ? <WorldScene /> : <CityScene />}
    </Canvas>
  );
}

import { FC, PropsWithChildren, useEffect } from 'react';

import { useGameStore } from '../../../store/game-store';
import { gs } from '../../../utils/clients/gs';
import { GameOnboardingPage } from '../../6_pages/GameOnboardingPage/GameOnboardingPage';

export const GameServerProvider: FC<PropsWithChildren> = ({ children }) => {
  const player = useGameStore(state => state.player);
  const setPlayer = useGameStore(state => state.setPlayer);
  const setLoading = useGameStore(state => state.setLoading);

  useEffect(() => {
    setLoading(true);
    // retrieve player from server
    gs.get('/player')
      .then(res => res.data)
      .then(player => {
        setPlayer(player);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setLoading, setPlayer]);

  if (!player) return <GameOnboardingPage />;

  return <>{children}</>;
};

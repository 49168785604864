import { MapControls, PerspectiveCamera } from '@react-three/drei';

import { FC, useRef } from 'react';

import { Camera } from 'three';

import { worldTerrainItems } from '../../data/world-env';
import { EnvItem } from '../CityScene/components/EnvItem';
import { HoldingList } from './components/HoldingList';
import { WorldTerrain } from './components/WorldTerrain';

export const WorldScene: FC = () => {
  const cameraRef = useRef<Camera>();

  return (
    <>
      <ambientLight intensity={0.5} />
      <directionalLight position={[0, 10, 0]} intensity={1.5} castShadow />
      <directionalLight position={[0, 0, -10]} intensity={1.5} />
      <fog attach="fog" args={['#0097fc', 10, 500]} />
      <color attach="background" args={['#0097fc']} />

      <group>
        {worldTerrainItems.map(item => (
          <EnvItem
            key={item.uid}
            model={item.model}
            x={item.x}
            z={item.z}
            rotation={item.rotation}
          />
        ))}
      </group>

      <HoldingList />

      <WorldTerrain />

      {/* <WorldHud /> */}
      <PerspectiveCamera
        ref={cameraRef}
        makeDefault
        position={[0, 4, 10]}
        receiveShadow
      />
      <MapControls minDistance={5} maxDistance={200} />
    </>
  );
};

import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import { Nullable } from '../types';

type GameStoreState = {
  loading: boolean;
  player: Nullable<string>;
  character: Nullable<string>;
  setLoading: (loading: boolean) => void;
  setPlayer: (player: Nullable<string>) => void;
  setCharacter: (character: Nullable<string>) => void;
};

export const useGameStore = create(
  immer<GameStoreState>(set => ({
    loading: false,
    player: null,
    character: null,
    setLoading: loading =>
      set(draft => {
        draft.loading = loading;
      }),
    setPlayer: player =>
      set(draft => {
        draft.player = player;
      }),
    setCharacter: character =>
      set(draft => {
        draft.character = character;
      }),
  })),
);

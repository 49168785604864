import { useCallback } from 'react';

import { DialMenu } from '../../../../components/DialMenu/DialMenu';
import { DialMenuItem } from '../../../../components/DialMenu/MenuItem';
import { useBuilderStore } from '../../../../store/game/builder-store';
import { useHoldingStore } from '../../../../store/game/holding-store';
import { gs } from '../../../../utils/clients/gs';

export const BuildingEditor = () => {
  const holding = useHoldingStore(state => state.selectedHolding);
  const updateBuildsin = useHoldingStore(state => state.setHoldings);
  const selected = useBuilderStore(state => state.selected);
  const clear = useBuilderStore(state => state.resetEdits);
  const rotate = useBuilderStore(state => state.rotateSelected);
  const select = useBuilderStore(state => state.selectBuilding);
  const edits = useBuilderStore(state => state.edits);

  const handleCancel = useCallback(() => {
    clear();
    select(null);
  }, [clear, select]);

  const handleConfirm = useCallback(() => {
    gs.put(`/holdings/${holding}/buildings`, {
      edits,
    }).then(() => {
      select(null);
      gs.get(`/holdings/${holding}`).then(({ data }) => {
        updateBuildsin([data]);
        clear();
      });
    });
  }, [clear, edits, holding, select, updateBuildsin]);

  const handleRotate = useCallback(() => {
    rotate();
  }, [rotate]);

  return (
    <scene visible={!!selected}>
      <DialMenu>
        <DialMenuItem label="Cancel" onClick={handleCancel} />
        <DialMenuItem label="Accept" onClick={handleConfirm} />
        <DialMenuItem label="Rotate" onClick={handleRotate} />
      </DialMenu>
    </scene>
  );
};

import { useMemo } from 'react';
import { Plane, Vector3 } from 'three';
import { useBuilderStore } from '../../../../store/game/builder-store';
import { useHoldingStore } from '../../../../store/game/holding-store';
import { Building } from '../Building';

export const Builder = () => {
  const buildings = useHoldingStore(
    state =>
      state.loadedHoldings.find(item => item.id === state.selectedHolding)
        ?.buildings || [],
  );

  const selected = useBuilderStore(state => state.selected);
  const newBuildings = useBuilderStore(state =>
    state.edits.filter(e => !e.hasOwnProperty('id') || e.id === 0),
  );

  const floorPlane = useMemo(() => new Plane(new Vector3(0, 1, 0), 0), []);

  return (
    <>
      <group>
        {buildings.map(item => (
          <Building
            key={item.id}
            uid={item.id.toString()}
            plane={floorPlane}
            building={item}
          />
        ))}
        {newBuildings.map(item => (
          <Building
            key={item.uid}
            uid={item.uid}
            plane={floorPlane}
            building={{
              id: 0,
              type: item.type!,
              level: 0,
              x: item.x,
              y: item.y,
              w: item.rotation,
            }}
          />
        ))}
      </group>
      {selected && <gridHelper args={[20, 80]} position={[0, 0.001, 0]} />}
    </>
  );
};

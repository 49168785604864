import {
  Children,
  cloneElement,
  FC,
  isValidElement,
  memo,
  ReactNode,
  useMemo,
} from 'react';

export type FormGroupProps = {
  label: ReactNode;
  children: ReactNode;
  id?: string;
};

export const FormGroup: FC<FormGroupProps> = memo(
  ({ label, children, id }: FormGroupProps) => {
    const htmlFor = useMemo(
      () => id || Math.random().toString(36).substr(2, 9),
      [id],
    );

    const renderChildren = useMemo(() => {
      return Children.map(children, child => {
        if (isValidElement(child)) {
          return cloneElement(child, {
            id: htmlFor,
          } as any);
        }

        return child;
      });
    }, [children, htmlFor]);

    return (
      <div className="mb-2 flex flex-col gap-2">
        <label htmlFor={htmlFor}>{label}</label>
        {renderChildren}
      </div>
    );
  },
);

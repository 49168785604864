import { MapControls } from '@react-three/drei';
import { useFrame, useThree } from '@react-three/fiber';

import { FC, useCallback, useMemo, useRef, useState } from 'react';

import { useAtom, useAtomValue } from 'jotai';
import { Vector3, Plane, Raycaster } from 'three';
import { v4 } from 'uuid';

import { Light } from '../../components/Light';
import { cityTerrainItems } from '../../data/city-env';
import { disableCameraPan, selectedBuildingAtom } from '../../store/atoms';
import { Terrain } from './Terrain';
import { Building, BuildingProps } from './components/Building';
import { CityHud } from './components/CityHud';
import { EnvItem } from './components/EnvItem';
import { useSelectedHolding } from './hooks/useSelectedHolding';
import { listBuildings } from '../../data/models/buildings';
import { randomBetween } from '../../utils/fiber';
import { BuildingType } from '../../types/models';
import { Builder } from './components/Builder/Builder';
import { BuildingEdit, useBuilderStore } from '../../store/game/builder-store';

export const CityScene: FC = () => {
  const holding = useSelectedHolding();

  const addBuilding = useBuilderStore(state => state.addBuilding);

  const disableCamera = useAtomValue(disableCameraPan);
  const [editing, selectEditingBuilding] = useAtom(selectedBuildingAtom);

  const cameraTarget = useRef<{ pos: Vector3; active: boolean }>({
    pos: new Vector3(0, 4, 0),
    active: false,
  });
  const camera = useThree(({ camera }) => camera);

  const [tempBuildings, setItems] = useState<BuildingProps[]>([]);

  const floorPlane = useMemo(() => new Plane(new Vector3(0, 1, 0), 0), []);
  const raycaster = useMemo(() => new Raycaster(), []);

  useFrame(({ camera }) => {
    if (cameraTarget.current.active) {
      camera.position.lerp(cameraTarget.current.pos, 0.1);
      if (camera.position.distanceTo(cameraTarget.current.pos) < 1) {
        cameraTarget.current.active = false;
      }
    }
  });

  const handleCameraUpdate = useCallback(
    (e: any) => {
      // const cam = e.target.object as Camera;
      // console.log(cam);
      // // console.log('change', cam.position, cam.rotation);
      // setCameraConfig({ position: cam.position, rotation: cam.rotation });
      camera.position.y = 4;
    },
    [camera],
  );

  const handleBuild = useCallback(
    (building: BuildingType) => {
      const item = listBuildings().find(item => item === building);
      if (item) {
        const uid = v4();

        const coords = new Vector3(0, 0, 0);

        raycaster.setFromCamera(new Vector3(0, 0, 0), camera);
        raycaster.ray.intersectPlane(floorPlane, coords);

        const build: BuildingEdit = {
          uid: uid,
          type: building,
          x: Math.round(coords.x),
          y: Math.round(coords.z),
          rotation: 0,
        };

        addBuilding(build);

        cameraTarget.current.pos.set(build.x, camera.position.y, build.y + 5);
        cameraTarget.current.active = true;
      }
    },
    [addBuilding, camera, floorPlane, raycaster],
  );

  if (!holding) return <></>;

  return (
    <>
      <Light />

      <group>
        {cityTerrainItems.map(item => (
          <EnvItem
            key={item.uid}
            model={item.model}
            x={item.x}
            z={item.z}
            rotation={item.rotation}
          />
        ))}
      </group>

      <Builder />

      <Terrain />

      <MapControls
        enablePan={!disableCamera}
        minDistance={5}
        maxDistance={50}
        onChange={handleCameraUpdate}
      />

      <CityHud onBuild={handleBuild} />
    </>
  );
};

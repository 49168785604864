import { User } from '@firebase/auth';

import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import { Nullable } from '../types';

type FirebaseState = {
  loading: boolean;
  user: Nullable<User>;
  idToken: Nullable<string>;
  setLoading: (loading: boolean) => void;
  setUser: (user: Nullable<User>) => void;
  setIdToken: (idToken: Nullable<string>) => void;
};

type AuthStoreState = {
  firebase: FirebaseState;
};

export const useAuthStore = create(
  immer<AuthStoreState>(set => ({
    firebase: {
      loading: true,
      user: null,
      idToken: null,
      setLoading: loading =>
        set(draft => {
          draft.firebase.loading = loading;
        }),
      setUser: user =>
        set(draft => {
          draft.firebase.user = user;
        }),
      setIdToken: idToken =>
        set(draft => {
          draft.firebase.idToken = idToken;
        }),
    },
  })),
);

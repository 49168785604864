import { useThree } from '@react-three/fiber';

import { FC } from 'react';

import { HudProvider } from '../../../components/5_providers/HudProvider/HudProvider';
import { BuildingType } from '../../../types/models';
import { BuildingList } from './BuildingList';
import { BuildingEditor } from './hud/BuildingEditor';

type CityHudProps = {
  onBuild: (building: BuildingType) => void;
};

export const CityHud: FC<CityHudProps> = ({ onBuild }) => {
  const size = useThree(({ size }) => size);
  return (
    <HudProvider>
      <BuildingList size={size} onBuild={onBuild} />
      <BuildingEditor />
    </HudProvider>
  );
};

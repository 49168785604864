import { FC } from 'react';

import { AuthForm } from './AuthForm';
import { ShardForm } from './ShardForm';

type LoginOnboardingPageProps = {
  hasAccount: boolean;
  hasShard: boolean;
};

export const LoginOnboardingPage: FC<LoginOnboardingPageProps> = ({
  hasAccount,
  hasShard,
}) => {
  if (!hasAccount) {
    return <AuthForm />;
  }

  if (!hasShard) {
    return <ShardForm />;
  }

  return <>All good now.</>;
};

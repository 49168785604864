export type IPlayer = {
  id: number;
  characterId: number;
  loginId: string;
  character: ICharacter;
};

export type ICharacter = {
  id: number;
  name: string;
  sex: string;
  lifeStage: string;
  dynasty: IDynasty;
  holdings: IHolding[];
};

export type IDynasty = {
  id: number;
  name: string;
  coa: ICoa;
};

export type ICoa = {
  shape: string;
  primaryColor: string;
  secondaryColor: string;
};

export enum HoldingType {
  TRIBE = 'TRIBE',
  CASTLE = 'CASTLE',
  CITY = 'CITY',
  TEMPLE = 'TEMPLE',
}

export type IHolding = {
  id: number;
  name: string;
  shortName: string;
  type: HoldingType;
  level: number;
  x: number;
  y: number;
  buildings: IBuilding[];
};

export enum BuildingType {
  CASTLE = 'CASTLE',
  BARRACKS = 'BARRACKS',
  FARM = 'FARM',
  MARKET = 'MARKET',
  TEMPLE = 'TEMPLE',
  HOUSES = 'HOUSES',
  HUT = 'HUT',
  WINDMILL = 'WINDMILL',
  FORTRESS = 'FORTRESS',
  CROPS = 'CROPS',
}

export type IBuilding = {
  id: number;
  type: BuildingType;
  level: number;
  x: number;
  y: number;
  w: number;
};

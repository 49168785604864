import { useGLTF } from '@react-three/drei';
import { GroupProps } from '@react-three/fiber';

import { FC } from 'react';

import { GLTF } from 'three-stdlib';

type GLTFResult = GLTF & {
  nodes: Record<string, THREE.Mesh>;
  materials: Record<string, THREE.Material>;
};

const typeFilter = (item: { type: string }) =>
  ['Object3D', 'Group', 'Mesh'].includes(item.type);

const RenderObject3D = (object: THREE.Object3D) => (
  <group key={object.uuid} dispose={null}>
    {object.children.filter(typeFilter).map(child => {
      if (child.type === 'Object3D') {
        return RenderObject3D(child as THREE.Object3D);
      }
      if (child.type === 'Group') {
        return RenderGroup(child as THREE.Group);
      }
      if (child.type === 'Mesh') {
        return RenderMesh(child as THREE.Mesh);
      }
      return <></>;
    })}
  </group>
);

const RenderMesh = (mesh: THREE.Mesh) => (
  <mesh
    key={mesh.uuid}
    castShadow
    receiveShadow
    geometry={mesh.geometry}
    material={mesh.material}
  />
);

const RenderGroup = (group: THREE.Group) => (
  <group
    key={group.uuid}
    position={group.position}
    rotation={group.rotation}
    scale={group.scale}
  >
    {group.children.filter(typeFilter).map(child => {
      if (child.type === 'Object3D') {
        return RenderObject3D(child as THREE.Object3D);
      }
      if (child.type === 'Group') {
        return RenderGroup(child as THREE.Group);
      }
      if (child.type === 'Mesh') {
        return RenderMesh(child as THREE.Mesh);
      }
      return <></>;
    })}
  </group>
);

export const GlbModel: FC<GroupProps & { model: string }> = ({
  model,
  ...props
}) => {
  const { scene } = useGLTF(model) as GLTFResult;
  return (
    <group {...props}>
      {scene.children.filter(typeFilter).map(child => {
        if (child.type === 'Object3D') {
          return RenderObject3D(child as THREE.Object3D);
        }
        if (child.type === 'Group') {
          return RenderGroup(child as THREE.Group);
        }
        if (child.type === 'Mesh') {
          return RenderMesh(child as THREE.Mesh);
        }
        return <></>;
      })}
    </group>
  );
};

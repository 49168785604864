import { BuildingType } from '../../types/models';
import { models } from '../models';

const buildingModels = {
  [BuildingType.CASTLE]: models.building_castle,
  [BuildingType.BARRACKS]: models.building_barracks,
  [BuildingType.MARKET]: models.building_market,
  [BuildingType.CROPS]: models.building_crops,
  [BuildingType.FARM]: models.building_farm,
  [BuildingType.FORTRESS]: models.building_fortress,
  [BuildingType.HOUSES]: models.building_house,
  [BuildingType.HUT]: models.building_hut,
  [BuildingType.TEMPLE]: models.building_temple,
  [BuildingType.WINDMILL]: models.building_windmill,
} as const;

export const getBuildingModel = (type: BuildingType, level: number) => {
  return buildingModels[type];
};

export const listBuildings = () =>
  Object.keys(buildingModels) as BuildingType[];

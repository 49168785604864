import {
  ChangeEvent,
  FC,
  FormEvent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useGameStore } from '../../../store/game-store';
import { gs } from '../../../utils/clients/gs';
import { randomHexColor } from '../../../utils/colors';
import { Button } from '../../1_atoms/Button/Button';
import { CoatOfArms } from '../../1_atoms/CoatOfArms/CoatOfArms';
import { FormGroup } from '../../1_atoms/FormGroup/FormGroup';
import { Input } from '../../1_atoms/Input/Input';

type FormState = {
  name: string;
  dynasty: string;
  // motto: string;
  shape: 'circle' | 'square';
  primaryColor: string;
  secondaryColor: string;
};

export const GameOnboardingPage: FC = () => {
  const storePlayer = useGameStore(state => state.setPlayer);
  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState<FormState>({
    name: '',
    dynasty: '',
    // motto: '',
    shape: 'circle',
    // random hex color
    primaryColor: randomHexColor(),
    secondaryColor: randomHexColor(),
  });

  const handleFormChange = useCallback(
    (key: keyof FormState) => (e: ChangeEvent<HTMLInputElement>) =>
      setForm(p => ({ ...p, [key]: e.target.value })),
    [],
  );

  const isFormValid = useMemo(
    () => Object.values(form).every(value => !!value && value.length > 3),
    [form],
  );

  const handleLogin = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      setLoading(true);

      gs.post('/player', form)
        .then(res => gs.get('/player'))
        .then(({ data }) => {
          storePlayer(data);
        })
        .catch(error => {
          setLoading(false);
          console.error(error);
          alert(error.message);
        });
    },
    [form, storePlayer],
  );

  return (
    <main className="w-full h-full flex flex-col items-center justify-center p-8">
      <div className="max-w-xs w-full bg-gray-400 rounded p-4">
        <form onSubmit={handleLogin} method="post">
          <h1 className="text-center mb-3">Create your Character</h1>
          <FormGroup label="Name" id="name">
            <Input value={form.name} onChange={handleFormChange('name')} />
          </FormGroup>

          <FormGroup label="Dynasty Name" id="dynasty">
            <Input
              value={form.dynasty}
              onChange={handleFormChange('dynasty')}
            />
          </FormGroup>
          {/* <FormGroup label="Dynasty Motto" id="motto">
            <Input value={form.motto} onChange={handleFormChange('motto')} />
          </FormGroup> */}
          <div className="flex flex-row justify-between items-center gap-3">
            <label>
              Coat of Arms
              <br />
              Colors
            </label>
            <div className="flex flex-col gap-1">
              <Input
                value={form.primaryColor}
                type="color"
                onChange={handleFormChange('primaryColor')}
                className="h-6 w-6 p-0 bg-transparent"
              />
              <Input
                value={form.secondaryColor}
                type="color"
                onChange={handleFormChange('secondaryColor')}
                className="h-6 w-6 p-0 bg-transparent"
              />
            </div>
            <div className="p-4">
              <CoatOfArms {...form} className="h-24" />
            </div>
          </div>

          <Button disabled={loading || !isFormValid} type="submit" primary>
            Start
          </Button>
        </form>
      </div>
    </main>
  );
};

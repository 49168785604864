export const Light = () => {
  return (
    <>
      <directionalLight
        castShadow
        position={[2.5, 8, 5]}
        intensity={2.5}
        shadow-mapSize={1024}
        color="yellow"
      >
        <orthographicCamera
          attach="shadow-camera"
          args={[-10, 10, -10, 10, 0.1, 50]}
        />
      </directionalLight>
      <ambientLight intensity={2.5} />
      <fog attach="fog" args={['#5891e1', 0, 50]} />
      <color attach="background" args={['#5891e1']} />
    </>
  );
};

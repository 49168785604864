import { useTexture } from '@react-three/drei';

import { RepeatWrapping } from 'three';

export const WorldTerrain = () => {
  const map = useTexture('/textures/map/parchmentAncient.png');
  map.wrapS = RepeatWrapping;
  map.wrapT = RepeatWrapping;
  map.repeat.set(4, 2);

  const table = useTexture('/textures/wood.jpg');

  return (
    <group rotation={[-Math.PI / 2, 0, 0]} position={[0, -0.1, 0]}>
      <mesh position={[0, 0, 0]} castShadow receiveShadow>
        <boxGeometry args={[200, 100, 0.2]} />
        <meshStandardMaterial map={map} />
      </mesh>
      <mesh position={[0, 0, -2.5]} castShadow receiveShadow>
        <boxGeometry args={[235, 135, 5]} />
        <meshStandardMaterial map={table} />
      </mesh>
      <mesh position={[-100, -50, -40]}>
        <boxGeometry args={[5, 5, 70]} />
        <meshStandardMaterial map={table} />
      </mesh>
      <mesh position={[-100, 50, -40]}>
        <boxGeometry args={[5, 5, 70]} />
        <meshStandardMaterial map={table} />
      </mesh>
      <mesh position={[100, -50, -40]}>
        <boxGeometry args={[5, 5, 70]} />
        <meshStandardMaterial map={table} />
      </mesh>
      <mesh position={[100, 50, -40]}>
        <boxGeometry args={[5, 5, 70]} />
        <meshStandardMaterial map={table} />
      </mesh>
    </group>
  );
};

import { GlbModel } from '../../../components/GlbModel';

type EnvItemProps = {
  model: string;
  x: number;
  z: number;
  rotation: number;
};

export const EnvItem = (props: EnvItemProps) => (
  <GlbModel
    model={props.model}
    position={[props.x, 0, props.z]}
    rotation={[0, props.rotation, 0]}
  />
);

import clsx from 'clsx';
import { forwardRef } from 'react';
import styles from './Input.module.css';

export const Input = forwardRef<
  HTMLInputElement,
  React.InputHTMLAttributes<HTMLInputElement>
>(({ className, ...props }, ref) => (
  <input ref={ref} className={clsx(styles.input, className)} {...props} />
));

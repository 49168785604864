import { atomWithStorage } from 'jotai/utils';
import { Euler, Vector3 } from 'three';

export type CameraConfig = {
  position: Vector3;
  rotation: Euler;
};

export const worldCameraConfig = atomWithStorage<CameraConfig>('camera.world', {
  position: new Vector3(0, 0, 0),
  rotation: new Euler(0, 45, 0),
});

export const cityCameraConfig = atomWithStorage<CameraConfig>('camera.city', {
  position: new Vector3(0, 4, 0),
  rotation: new Euler(0, 25, 0),
});

import { Html } from '@react-three/drei';
import { Size } from '@react-three/fiber';

import { FC, useCallback, useState } from 'react';

import { listBuildings } from '../../../data/models/buildings';
import { useHoldingStore } from '../../../store/game/holding-store';
import { BuildingType } from '../../../types/models';

type BuildingListProps = {
  size: Size;
  onBuild: (building: BuildingType) => void;
};

export const BuildingList: FC<BuildingListProps> = ({ size, onBuild }) => {
  const setSelectedHolding = useHoldingStore(state => state.setSelectedHolding);

  const [show, setShow] = useState(false);
  const handleBuilding = useCallback(
    (building: BuildingType) => (e: any) => {
      e.stopPropagation();
      setShow(false);
      onBuild(building);
    },
    [onBuild],
  );

  return (
    <Html position={[size.width / 2 - 240, size.height / 2 - 20, 0]}>
      <div className="text-white font-bold">
        <div className="flex flex-row justify-between items-center gap-4 w-32">
          <button
            onClick={e => {
              e.stopPropagation();
              setShow(e => !e);
            }}
          >
            Buildings
          </button>
          <button
            onClick={e => {
              e.stopPropagation();
              setSelectedHolding(0);
            }}
          >
            World Map
          </button>
        </div>
        {show && (
          <ul>
            {listBuildings().map(building => (
              <li key={building}>
                <button onClick={handleBuilding(building)}>{building}</button>
              </li>
            ))}
          </ul>
        )}
      </div>
    </Html>
  );
};

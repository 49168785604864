import { Text } from '@react-three/drei';
import { GroupProps, ThreeEvent } from '@react-three/fiber';

import { FC, useCallback, useState } from 'react';

export type DialMenuItemProps = {
  label: string;
  onClick: () => void;
  disabled?: boolean;
};

export const DialMenuItem: FC<DialMenuItemProps & GroupProps> = ({
  label,
  onClick,
  disabled,
  ...props
}) => {
  const handleClick = useCallback(
    (event: ThreeEvent<MouseEvent>) => {
      event.stopPropagation();
      onClick();
    },
    [onClick],
  );
  const [hovered, setHovered] = useState(false);
  return (
    <group
      {...props}
      onClick={handleClick}
      onPointerEnter={() => setHovered(true)}
      onPointerLeave={() => setHovered(false)}
    >
      <mesh>
        <circleGeometry args={[0.8, 32]} />
        <meshBasicMaterial
          color={disabled ? '#525252' : hovered ? '#0033ff' : '#0098be'}
        />
      </mesh>
      <Text fontSize={0.4} color="#fff">
        {label}
      </Text>
    </group>
  );
};

import { degToRad } from 'three/src/math/MathUtils';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { Nullable } from '../../types';
import { BuildingType } from '../../types/models';

type EditableBuildingState = {
  x: number;
  y: number;
  rotation: number;
};

export type BuildingEdit = {
  uid: string;
  id?: number;
  type?: BuildingType;
} & EditableBuildingState;

type BuilderStoreState = {
  selected: Nullable<string>;
  edits: BuildingEdit[];
  selectBuilding: (uid: Nullable<string>) => void;
  addBuilding: (building: BuildingEdit) => void;
  updateBuilding: (
    uid: string,
    update: Partial<EditableBuildingState>,
    id?: any,
  ) => void;
  resetEdits: () => void;
  rotateSelected: () => void;
};

export const useBuilderStore = create(
  immer<BuilderStoreState>(set => ({
    selected: null,
    edits: [],
    selectBuilding: uid =>
      set(draft => {
        draft.selected = uid;
      }),
    addBuilding: building =>
      set(draft => {
        draft.edits.push(building);
        draft.selected = building.uid;
      }),
    updateBuilding: (uid, update, id?: number) =>
      set(draft => {
        const edit = draft.edits.find(edit => edit.uid === uid);
        if (edit) {
          Object.assign(edit, update);
        } else {
          draft.edits.push({ uid, ...update, ...{ id } } as any);
        }
      }),
    resetEdits: () =>
      set(draft => {
        draft.edits = [];
      }),
    rotateSelected: () =>
      set(draft => {
        const edit = draft.edits.find(edit => edit.uid === draft.selected);
        if (edit) {
          edit.rotation = (edit.rotation ?? 0) + degToRad(45);
        }
      }),
  })),
);

import { ButtonHTMLAttributes, forwardRef, PropsWithChildren } from 'react';

import clsx from 'clsx';

import styles from './Button.module.css';

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  primary?: boolean;
  secondary?: boolean;
};

export const Button = forwardRef<
  HTMLButtonElement,
  PropsWithChildren<ButtonProps>
>(
  (
    {
      onMouseDown,
      onMouseUp,
      onMouseEnter,
      onMouseLeave,
      className,
      primary,
      secondary,
      ...props
    },
    ref,
  ) => {
    // const [playActive] = useSound(popDownSfx);
    // const [playOn] = useSound(popUpOnSfx);
    // const [playOff] = useSound(popUpOffSfx);

    // const handleMouseDown = useCallback(
    //   (event: MouseEvent<HTMLButtonElement>) => {
    //     playActive();
    //     onMouseDown?.(event);
    //   },
    //   [onMouseDown, playActive],
    // );

    // const handleMouseUp = useCallback(() => {
    //   if (props.type === 'submit') {
    //     playOn();
    //   } else {
    //     playOff();
    //   }
    // }, [playOff, playOn, props.type]);

    return (
      <button
        ref={ref}
        // onMouseDown={handleMouseDown}
        // onMouseUp={handleMouseUp}
        className={clsx(
          styles.button,
          {
            [styles.primary]: primary,
            [styles.secondary]: secondary,
          },
          className,
        )}
        {...props}
      />
    );
  },
);

import { Hud, OrthographicCamera } from '@react-three/drei';

import { FC, PropsWithChildren } from 'react';

import { create } from 'zustand';

type HudState = {
  camera: THREE.Camera | null;
  setCamera: (camera: THREE.Camera | null) => void;
};

export const useHudStore = create<HudState>(set => ({
  camera: null,
  setCamera: (camera: THREE.Camera | null) => set({ camera }),
}));

export const HudProvider: FC<PropsWithChildren> = ({ children }) => {
  const setCamera = useHudStore(state => state.setCamera);
  return (
    <Hud>
      <OrthographicCamera makeDefault position={[0, 0, 100]} ref={setCamera} />
      <group>{children}</group>
    </Hud>
  );
};

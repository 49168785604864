import { useCallback, useLayoutEffect } from 'react';
import { useHoldingStore } from '../../../store/game/holding-store';
import { useMapStore } from '../../../store/game/map-store';
import { HoldingType } from '../../../types/models';
import { gs } from '../../../utils/clients/gs';
import { Holding } from './Holding';

export const HoldingList = () => {
  const selectHolding = useHoldingStore(state => state.setSelectedHolding);

  const holdings = useMapStore(state => state.holdings);
  const setHoldings = useMapStore(state => state.setHoldings);
  const setLoading = useMapStore(state => state.setLoading);

  const handleClick = useCallback(
    (id: number) => {
      selectHolding(id);
    },
    [selectHolding],
  );

  useLayoutEffect(() => {
    setLoading(true);
    gs.get('/map')
      .then(({ data }) => {
        setHoldings(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setHoldings, setLoading]);

  return (
    <group>
      {holdings.map(holding => (
        <Holding key={holding.id} holding={holding} onClick={handleClick} />
      ))}

      {/* spawn fake holding to prevent camera glitch */}
      <Holding
        holding={{
          id: 0,
          name: 'Test',
          shortName: 'Test',
          type: HoldingType.TEMPLE,
          x: 1,
          y: -10000,
          level: 0,
          buildings: [],
        }}
        onClick={() => {}}
      />
    </group>
  );
};

import {
  ChangeEvent,
  FC,
  FormEvent,
  useCallback,
  useMemo,
  useState,
} from 'react';

import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from 'firebase/auth';

import { Button } from '../../1_atoms/Button/Button';
import { FormGroup } from '../../1_atoms/FormGroup/FormGroup';
import { Input } from '../../1_atoms/Input/Input';
import { auth } from '../../../config/firebase';

type FormState = {
  email: string;
  password: string;
};

export const AuthForm: FC = () => {
  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState<FormState>({
    email: '',
    password: '',
  });

  const handleFormChange = useCallback(
    (key: keyof FormState) => (e: ChangeEvent<HTMLInputElement>) =>
      setForm(p => ({ ...p, [key]: e.target.value })),
    [],
  );

  const isFormValid = useMemo(
    () => Object.values(form).every(value => !!value && value.length > 5),
    [form],
  );

  const handleLogin = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      setLoading(true);
      signInWithEmailAndPassword(auth, form.email, form.password)
        .then(credentials => {
          console.log({ credentials });
        })
        .catch(error => {
          console.error(error);
          alert(error.message);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [form],
  );

  const handleSignUp = useCallback(() => {
    setLoading(true);
    createUserWithEmailAndPassword(auth, form.email, form.password)
      .then(credentials => {
        console.log({ credentials });
      })
      .catch(error => {
        console.error(error);
        alert(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [form]);

  return (
    <main className="w-full h-full flex flex-col items-center justify-center p-8">
      <div className="max-w-xs w-full bg-gray-400 rounded p-4">
        <form onSubmit={handleLogin} method="post">
          <FormGroup label="Email" id="email">
            <Input
              value={form.email}
              onChange={handleFormChange('email')}
              autoComplete="email"
            />
          </FormGroup>
          <FormGroup label="Password" id="password">
            <Input
              type="password"
              value={form.password}
              onChange={handleFormChange('password')}
              autoComplete="current-password"
            />
          </FormGroup>

          <Button disabled={loading || !isFormValid} type="submit" primary>
            Login
          </Button>

          <Button
            onClick={handleSignUp}
            disabled={loading || !isFormValid}
            type="button"
            secondary
          >
            Sign up
          </Button>
        </form>
      </div>
    </main>
  );
};

import { Text } from '@react-three/drei';
import { ThreeEvent } from '@react-three/fiber';

import { FC, useCallback } from 'react';

import { GlbModel } from '../../../components/GlbModel';
import { getHoldingModel } from '../../../data/models/holdings';
import { IHolding } from '../../../types/models';

type HoldingProps = {
  holding: IHolding;
  onClick: (id: number) => void;
};

export const Holding: FC<HoldingProps> = ({ holding, onClick }) => {
  const handleClick = useCallback(
    (event: ThreeEvent<MouseEvent>) => {
      event.stopPropagation();
      onClick(holding.id);
    },
    [holding.id, onClick],
  );
  return (
    <group position={[holding.x, 0, holding.y]}>
      <group onClick={handleClick}>
        <GlbModel model={getHoldingModel(holding.type, holding.level)} />
        <group position={[0, 0.01, 1.5]} rotation={[-Math.PI / 2, 0, 0]}>
          <Text color="black" fontSize={0.5}>
            {holding.name}
          </Text>
        </group>
      </group>
    </group>
  );
};

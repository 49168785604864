import { animated, config, useSpring } from '@react-spring/three';
import { useGesture } from '@use-gesture/react';

import { FC, useRef } from 'react';

import { useSetAtom } from 'jotai';
import { Plane, Vector3 } from 'three';

import { GlbModel } from '../../../components/GlbModel';
import { disableCameraPan } from '../../../store/atoms';
import { IBuilding } from '../../../types/models';
import { getBuildingModel } from '../../../data/models/buildings';
import { useBuilderStore } from '../../../store/game/builder-store';
import { clamp } from 'three/src/math/MathUtils';

const UP = 0.5;

export type BuildingProps = {
  uid: string;
  building: IBuilding;
  plane?: Plane;
  onClick?: (id: number) => void;
};

export const Building: FC<BuildingProps> = ({ uid, building, plane }) => {
  const edit = useBuilderStore(state =>
    state.edits.find(edit => edit.uid === uid),
  )!;

  const selected = useBuilderStore(state => state.selected);
  const select = useBuilderStore(state => state.selectBuilding);
  const update = useBuilderStore(state => state.updateBuilding);

  const disablePan = useSetAtom(disableCameraPan);
  const isEditing = selected === String(uid);

  const planeIntersectPoint = useRef(new Vector3());
  const dragStartPoint = useRef(new Vector3());

  const dragObjectRef = useRef<any>();

  const [props, api] = useSpring(
    () => ({
      up: 0,
      position: [edit?.x || building.x, 0, edit?.y || building.y],
      rotation: edit?.rotation ?? building.w ?? 0,
      config: config.wobbly,
    }),
    [isEditing, edit?.x, edit?.y, edit?.rotation, building.w],
  );

  const handleDrag = (event: any) => {
    if (isEditing) {
      event.ray.intersectPlane(plane, planeIntersectPoint.current);
      const { x, z } = {
        x: clamp(
          Math.round(
            (planeIntersectPoint.current.x - dragStartPoint.current.x) * 4,
          ) / 4,
          -10,
          10,
        ),
        z: clamp(
          Math.round(
            (planeIntersectPoint.current.z - dragStartPoint.current.y) * 4,
          ) / 4,
          -10,
          10,
        ),
      };
      api.start({
        position: [x, 0, z],
      });
      update(
        uid,
        { x, y: z, rotation: edit?.rotation ?? building.w ?? 0 },
        building.id,
      );
    }
  };

  const bind = useGesture(
    {
      onDrag: ({ active, event }) => {
        disablePan(active);
        if (active) {
          handleDrag(event);
        }
      },
      onDragStart: ({ event }) => {
        update(uid, {}, building.id);
        select(uid);
        // (event as any).ray.intersectPlane(plane, dragStartPoint.current);
        // const { x, z } = {
        //   x: clamp(Math.round(dragStart.x * 4) / 4, -10, 10),
        //   z: clamp(Math.round(dragStart.z * 4) / 4, -10, 10),
        // };
        // api.start({ up: UP, position: [x, 0, z] });
      },
      onDragEnd: ({ event }) => {
        api.start({ up: 0 });
        handleDrag(event);
        // update(uid, { x: pos[0], y: pos[1] }, building.id);
      },
      onPointerDown: () => {
        api.start({ up: UP });
      },
    },
    {
      drag: {
        delay: true,
      },
    },
  );

  return (
    <animated.mesh
      ref={dragObjectRef}
      position={props.position.to((x, y, z) => [x, y, z])}
      receiveShadow
      castShadow
    >
      <animated.group
        {...(bind() as any)}
        position={props.up.to(y => [0, y, 0])}
        rotation={props.rotation.to(r => [0, r, 0])}
      >
        <GlbModel model={getBuildingModel(building.type, building.level)} />
      </animated.group>
    </animated.mesh>
  );
};

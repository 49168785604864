import { GroupProps, useThree } from '@react-three/fiber';

import React, { FC, PropsWithChildren, useMemo, useRef } from 'react';

import { Group } from 'three';

const ANGLE = 1.5;

export type DialMenuProps = {};

export const DialMenu: FC<PropsWithChildren<DialMenuProps> & GroupProps> = ({
  children,
  ...props
}) => {
  const ref = useRef<Group>(null);
  const size = useThree(({ size }) => size);

  const items = useMemo(
    () =>
      React.Children.map(children, (child, index) => {
        const angle = (index / React.Children.count(children)) * Math.PI * 2;
        const x = -Math.cos(angle) * ANGLE;
        const y = -Math.sin(angle) * ANGLE;
        return React.cloneElement(child as React.ReactElement, {
          position: [x, y, 0],
        });
      }),
    [children],
  );

  return (
    <group ref={ref} position={[0, -size.height / 2 + 50, -100]} scale={25}>
      {items}
    </group>
  );
};

import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { IHolding } from '../../types/models';

type HoldingStoreState = {
  loading: boolean;
  selectedHolding: number;
  loadedHoldings: IHolding[];
  setLoading: (loading: boolean) => void;
  setSelectedHolding: (holding: number) => void;
  getSelectedHolding: () => IHolding | undefined;
  setHoldings: (holdings: IHolding[]) => void;
  getHolding: (holdingId: number) => IHolding | undefined;
};

export const useHoldingStore = create(
  persist(
    immer<HoldingStoreState>((set, get) => ({
      loading: false,
      selectedHolding: 0,
      loadedHoldings: [],
      setLoading: loading =>
        set(draft => {
          draft.loading = loading;
        }),
      setSelectedHolding: holding =>
        set(draft => {
          draft.selectedHolding = holding;
        }),
      setHoldings: holdings =>
        set(draft => {
          draft.loadedHoldings = holdings.map(holding => {
            const existingHolding = draft.loadedHoldings.find(
              loadedHolding => loadedHolding.id === holding.id,
            );
            if (existingHolding) {
              return holding;
            }
            return holding;
          });
        }),
      getHolding: holdingId => {
        const { loadedHoldings } = get();
        return loadedHoldings.find(holding => holding.id === holdingId);
      },
      getSelectedHolding: () => {
        const { loadedHoldings, selectedHolding } = get();
        return loadedHoldings.find(holding => holding.id === selectedHolding);
      },
    })),
    {
      name: 'holding',
      partialize: state => ({
        selectedHolding: state.selectedHolding,
        loadedHoldings: state.loadedHoldings,
      }),
    },
  ),
);

export const Terrain = () => {
  return (
    <>
      <mesh receiveShadow rotation={[-Math.PI / 2, 0, 0]} position={[0, 0, 0]}>
        <planeGeometry attach="geometry" args={[1000, 1000]} />
        <meshStandardMaterial attach="material" color="#006817" />
      </mesh>
    </>
  );
};
